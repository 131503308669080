import React from 'react'
import Showdown from 'showdown'
import { graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from '../components/layout'
import Hero from '../components/heroSubpage'
import SEO from '../components/seo'
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fat } from '@fortawesome/pro-thin-svg-icons'
import { removeURLParameter } from '../utils/helpers.js'

library.add(fat)

const SupportProductTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const heroImage = data.heroImage
  const rohsImage = data.rohsImage
  const converter = new Showdown.Converter()
  Showdown.setOption('customizedHeaderId',true)   

  let url = new URL(typeof window !== 'undefined' ? window.location.href : data.site.siteMetadata.siteUrl + location.pathname)
  removeURLParameter(url, 'prod')

  return (
    <div id="sub-page">
      <Layout>
        <SEO
          title={`${post.frontmatter.name} product support`}
          description={post.frontmatter.meta.description}
          url={data.site.siteMetadata.siteUrl + location.pathname} 
          image={data.site.siteMetadata.siteUrl + heroImage.childImageSharp?.gatsbyImageData?.images?.fallback?.src}
        />

        <Hero
          class="intro-65"
          image={heroImage?.childImageSharp?.gatsbyImageData}
          foreimage={null}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          type="root"
        />

        <section className="bg-white">
          <MDBContainer>
            {post.frontmatter.support.title &&
              <MDBRow>
                <MDBCol>
                  <p className="pl-2"><strong>{post.frontmatter.support.title}</strong></p>
                </MDBCol>
              </MDBRow>
            }


            {post.frontmatter.support.intro &&
              <MDBRow>
                <MDBCol>
                  <div
                    className="my-3 richtext divlink note note-light"
                    dangerouslySetInnerHTML={{ __html: converter.makeHtml(post.frontmatter.support.intro) }}
                  />
                </MDBCol>
              </MDBRow>
            }

            <MDBRow className="pt-5">
              <MDBCol md="12" lg="9" className="bg-light-blue">
                {post.frontmatter.support.section.map((support, index) => (
                  <MDBRow className={`support-section${support.title.toLowerCase().includes("compatibility") ? " os-list" : ""}`} key={index}>
                    <MDBCol>
                      {/* <div className="support-section-block"> */}
                        <h3 className="font-alt font-w-700 letter-spacing-1 title-small title-xs-medium">
                          {support.title}
                        </h3>
                        <div
                          className="mt-3 richtext divlink"
                          dangerouslySetInnerHTML={{ __html: converter.makeHtml(support.description) }}
                        />
                      {/* </div> */}
                      {index !== (post.frontmatter.support.section.length - 1) && <hr className="support-section-line mt-5" />}
                    </MDBCol>  
                  </MDBRow>
                ))}
              </MDBCol>

              <MDBCol lg="3" md="12" className="pl-lg-4">
                <MDBRow className="support-side">
                  <MDBCol>
                    {post.frontmatter.support.warranty &&
                    <div className="pt-5 pb-3">
                      <strong>Warranty:</strong> <a href={post.frontmatter.support.warranty.link} className="effect" target="_blank" rel="noopener">{post.frontmatter.support.warranty.linktext}</a>
                    </div>
                    }
                    { post.frontmatter.support.image && 
                      <GatsbyImage image={post.frontmatter.support.image.childImageSharp.gatsbyImageData} alt={post.frontmatter.support.alttext} />
                    }
                    {post.frontmatter.support.rohs &&
                      <GatsbyImage image={rohsImage.childImageSharp.gatsbyImageData} alt="RoHS compliant" />
                    }

                    <hr className="support-section-line mb-4" />

                    {post.frontmatter.support.side.map((side, index) => (
                      <div className="headline-tile mt-lg-3 item-headline" key={index}>
                        <div className="headline-content">
                          <h3 className="font-alt font-w-700 letter-spacing-1 text-xs-medium text-medium">
                            {side.title}
                          </h3>
                          <div
                            className="mt-3 divlink text-xs-medium text-small"
                            dangerouslySetInnerHTML={{ __html: converter.makeHtml(side.description) }}
                          />
                        </div>
                      </div>
                    ))}
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>
      </Layout>
    </div>
  )
}
export default SupportProductTemplate

export const query = graphql`
  query ($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    markdownRemark( fields: { slug: { eq: $slug } } frontmatter: { template: { eq: "support-products" } } ) {
      html
      frontmatter {
        meta {
          title
          description 
        }
        name
        title
        subtitle
        ctatext
        ctaslug        
        openingpara
        section {
          title
          description
          feature {
            title
            icon
            description
            linktext
            link
          }
        }
        carousel {
         title 
          prodimage {
            title
            image {
              childImageSharp {
                gatsbyImageData(quality: 90)
              }
            }
            alttext
            }
        }
        faq {
          question
          answer
        }
        helpfulresources {
          title
          subtitle
          helpfulres {
            title
            subtitle
            description
            placement
            link
            alttext
            image {
              childImageSharp {
                gatsbyImageData(quality: 90)
              }
            }
            document {
              publicURL
            }
          }
        }
        cta {
          title
          description
          linktext
          link
        }
        support {
          title          
          intro
          image {
            childImageSharp {
              gatsbyImageData(quality: 90)
            }
          } 
          alttext
          rohs
          warranty {
            linktext
            link
          }
          section {
            title
            description 
          }
          side {
            title
            description
          }
        }
      }
    }
    heroImage: file(name: { eq: "product-banner" }) {
      childImageSharp {
        gatsbyImageData(quality: 90)
      }
    }
    rohsImage: file(name: { eq: "rohs-badge" }) {
      childImageSharp {
        gatsbyImageData(quality: 90)
      }
    }
  }
`